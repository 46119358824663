body.ie {
    
    .page-section {
        &.first-page {
            .btn-group {
                .btn-homepage {
                    height:45px;
                    img {
                        height:40px;
                    }
                    .btn-homepage-color {
                        display:none;
                    }
                }                   
            }
        }
        &.product-page {
            .product-details {
                img {
                    height:80px;
                }
            }
        }
    }
    
    .mousewheel {
        img {
            opacity: 1;
            -webkit-transform:translateY(0px);
            transform:translateY(0px);
            
            transition:transform 0.4s, opacity 0.4s, -webkit-transform 0.4s;
            
            &.animate-mouse {
                opacity:0;
                -webkit-transform:translateY(50px);
                transform:translateY(50px);
            }   
        }
    }
    
    
    .hidden-ie {
        display:none;
    }
    .visible-ie {
        display:block;
    }
    
}