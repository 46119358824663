* {
    box-sizing:border-box;
}


body {
    margin:0px;
    font-family:"Helvetica Neue", Helvetica, sans-serif;
    padding-top:80px;
}

a, 
a:hover,
a:focus,
a:active {
    text-decoration:none;
}


p, li {
    font-weight:300;
    font-size:16px;
    line-height:32px;
}

table, td, th {  
    border: 1px solid #ddd;
    text-align: left;
}

table {
    border-collapse: collapse;
    width: 100%;
}

th, td {
    padding: 15px;
}


h1 {
  font-weight:300;
}

.content-wrapper {
  padding:50px 0px;
}



.boilerplate {
  // padding:20px 0px;
  h1 {
    margin-bottom:0px;
  }
  .tabs {
    margin-bottom:80px;
  }
  
  .col-md-4 {
    position: fixed;
    z-index: 10;
    width: 390px;
  }
  
  .tab-content {
    position:relative;
  }
  .copy {
    position:absolute;
    right:0px;
    top:0px;
    padding:4px 10px;
    background:$primary-color;
    color:white;
    border-bottom-left-radius:4px;
    &:hover {
      cursor:pointer;
      background-color:darken($primary-color, 10);
    }
  }
  
}

code { 
  background: #f9f9f9 !important;
}

pre {
    white-space: pre-wrap;
    background: #f9f9f9;
    padding:10px 0px;
    border-radius:0px;
    margin:0px;
}

.anchor {
  visibility: hidden;
  position: relative;
  top:-80px;
}


.nav-title {
  float:left; 
  margin-left:30px; 
  color:#b1b1b1; 
  font-weight:100;
  letter-spacing: 1.2px;
}