// includes
@import "./boostrap.scss";
@import "./fontawesome/font-awesome.scss";

// base theme
@import "./theme.scss";

// base layout
@import "./header.scss";
@import "./footer.scss";
@import "./base.scss";
@import "./slick.scss";
@import "./lightbox.scss";

// custom scss
@import "./custom.scss";
@import "./mobile.scss";


// gross
@import "./ie.scss";