////////////////////////////////////////////////
// Nav block
////////////////////////////////////////////////

.navbar {
    position:fixed;
    height:80px;
    background:white;
    width:100%;
    z-index:100;
    top:0px;
    border-bottom:1px solid #EFEFEF;
    box-shadow:0px 0px 4px 0px rgba(0,0,0,0.1);
    .container {
        position:relative;
        height:100%;
    }
    .nav-wrapper {
        position:relative;
        height:100%;
        
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-align-items:center;        
        -ms-flex-align:center;        
        align-items:center;
        
    }
    
    .nav,
    .nav-links {
        > li {
            float:left;
            list-style:none;
            margin-left:0px;
            position:relative;
            a {
                display:block;
                font-size:16px;
                margin:0px;
                color:#B1B1B1;
                text-decoration: none;
                background:transparent;
                padding:6px 10px;
                transition:color 0.5s;
            }
            &.active,
            &:hover {
                > a {
                    color:$primary-color;
                }
            }
            ul {
                padding: 10px 0px;
                position:absolute;
                background:$primary-color;
                width:250px;
                top:100%;
                // left:0px;
                right:0px;
                display:none;
                li {
                    list-style:none;
                    a {
                        color:white;
                        padding: 3px 20px;
                        font-size: 14px;
                        transition:background 0.4s;
                        &:hover {
                            color:#fff;
                            background-color:darken($primary-color, 10);
                        }
                    }
                }
                
            }
            &:hover {
                .dropdown {
                    display:block;
                }
            }
        }
    }
    
    .nav {
        position:relative;
        padding:0px; 
        margin:0px;
        // top:10px;
    }
    
    .nav-links {
        position:absolute;
        top:0px;
        right:15px;
        margin:0px;
        li {
            a {
                font-size:12px;
            }
        }
    }
    
    .nav-toggle {
        position: absolute;
        right: 0px;
        width: 30px;
        height: 18px;
        top: 50%;
        margin-top: -14px;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        transition: -webkit-transform 0.4s;
        transition:transform 0.4s;
        transition: transform 0.4s, -webkit-transform 0.4s;
        .line {
            background-color: $primary-color;
            position: absolute;
            left: 0;
            right: 0;
            height: 2px;
            -webkit-transform: translate(0px, 0px) rotate(0deg);
            transform: translate(0px, 0px) rotate(0deg);
            transition: -webkit-transform 0.4s, opacity 0.4s;
            transition:opacity 0.4s, -webkit-transform 0.4s;
            transition:transform 0.4s, opacity 0.4s;
            transition: transform 0.4s, opacity 0.4s, -webkit-transform 0.4s;
            &:nth-child(1) {
                top: 0%;
            }
            &:nth-child(2) {
                top: 50%;
                opacity: 1;
            }
            &:nth-child(3) {
                top: 100%;
            }
        }
        &.active {
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
            .line {
                &:nth-child(1) {
                    -webkit-transform: rotate(45deg) translate(5px, 6.7px);
                    transform: rotate(45deg) translate(5px, 6.7px);
                }
                &:nth-child(2) {
                    opacity:0
                }
                &:nth-child(3) {
                    -webkit-transform: rotate(-45deg) translate(6px, -7px);
                    transform: rotate(-45deg) translate(6px, -7px);
                }
            }
        }
    }
    
}

// Responsiveness
@media(max-width:992px) {
    
    .nav {
        background:#f0f0f0;
        position:fixed !important;
        width:300px;
        top:80px;
        right:-300px;
        bottom:0px;
        padding: 10px 15px !important;
        -webkit-transform:translateX(0px);
        transform:translateX(0px);
        transition:-webkit-transform 0.4s;
        transition:transform 0.4s;
        transition:transform 0.4s, -webkit-transform 0.4s;
        > li {
            float:none !important;
            display:block;
            ul {
                display:none !important;
            }
        }
        &.active {
            -webkit-transform:translateX(-300px);
            transform:translateX(-300px);
        }
    }
    
}



////////////////////////////////////////////////
// End Nav Block
////////////////////////////////////////////////





////////////////////////////////////////////////
// Banner Block
////////////////////////////////////////////////

.banners {
    height:700px;
    position:relative;
    .banner-wrapper {
        height:700px;
        outline:none !important;
        .banner {
            height:700px;
            background-size:cover;
            background-position:center center;
        }
    }
    .container {
        position:relative;
    }
    .banner-overlay {
        position:absolute;
        width:500px;
        max-width:100%;
        left:15px;
        bottom:30px;
        padding:10px 20px;
        background:$primary-color;
        color:white;
    }
    &.content-banners {
        height:500px;
        .banner {
            height:500px;
        }
        .banner-overlay {
            display:none;
        }
    }
    
    // slick js styles
    .slick-arrow {
        position:absolute;
        top:50%;
        background:none;
        border:none;
        color:$primary-color;
        font-size:48px;
        outline:none;
        z-index:100;
        -webkit-transform:translateY(-50%);
        transform:translateY(-50%);
        &.slick-next {
            right:10px;
        }
        &.slick-prev {
            left:10px;
        }
    }
}

////////////////////////////////////////////////
// End Banner Block
////////////////////////////////////////////////




////////////////////////////////////////////////
// Quicklink Block
////////////////////////////////////////////////

.quicklinks {
    
    padding:30px 0px;
    margin:0px auto;
    .quicklink {
        text-align:center;
        img {
            display:block;
            max-width:100%;
            width:100%;
        }
        span {
            display:block;
            color:#B1B1B1;
            font-size:16px;
            margin:10px 0px;
        }
    }
}

////////////////////////////////////////////////
// End Quicklink Block
////////////////////////////////////////////////



////////////////////////////////////////////////
// Promo Block
////////////////////////////////////////////////

.promo {
    display:block;
    padding:30px 0px;
    margin:0px auto;
    img {
        display:block;
        max-width:100%;
    }
}

////////////////////////////////////////////////
// End Promo Block
////////////////////////////////////////////////


////////////////////////////////////////////////
// Events Block
////////////////////////////////////////////////

.events {
    background:#f9f9f9;
    padding:50px 0px;
    margin:0px auto;
    .event {
        .event-date {
            float: left;
            width: 90px;
            height: 90px;
            background: #4077c6;
            color: #fff;
            text-align: center;
            font-weight: 300;
            text-transform: uppercase;
            font-size: 14px;
            line-height: 16px;
            padding: 13px 10px;
            margin-right:10px;
            .day {
                font-size: 30px;
                line-height: 38px;
                margin: 0 0 4px;
                display:block;
            }
        }
        
        .event-details {
            .description {
                font-size: 16px;
                line-height: 24px;
                display: block;
                margin-bottom: 11px;
            }
        }
    }
}

////////////////////////////////////////////////
// End events Block
////////////////////////////////////////////////





////////////////////////////////////////////////
// Lead Block
////////////////////////////////////////////////

.lead {
    font-size:18px;
    font-weight:400;
}

////////////////////////////////////////////////
// End Lead Block
////////////////////////////////////////////////





////////////////////////////////////////////////
// Sidebar
////////////////////////////////////////////////

.sidebar {
    background:$primary-color;
    padding:15px 0px;
    h1 {
        margin:0px;
        color:white;
        padding:0px;
        margin:0px 20px;
        border-bottom:2px solid white;
    }
    .nav {
        padding:0px;
        li {
            list-style:none;
            margin:0px;
            a {
                color:white;
                display:block;
                padding: 3px 20px;
                font-size: 14px;
                transition:background 0.4s;
                &:hover {
                    background:darken($primary-color, 10);
                }
            }
        }
        > li {
            ul {
                padding:0px;
                li {
                    a {
                        font-size:13px;
                        padding:2px 45px;
                    }
                }
            }
        }
    }
}


////////////////////////////////////////////////
// End Sidebar
////////////////////////////////////////////////



////////////////////////////////////////////////
// Quote
////////////////////////////////////////////////

.quote {
    padding-left:30px;
    position:relative;
    margin:30px 0px;
    &:before {
        content:'';
        position:absolute;
        background:$primary-color;
        left:0px;
        top:0px;
        bottom:0px;
        width:3px;
    }
    .title {
        font-size:18px;
        font-weight:300;
    }
    .byline {
        display:block;
        font-size:12px;
        font-weight:600;
        margin-top:8px;
    }
}


////////////////////////////////////////////////
// End Quote
////////////////////////////////////////////////


.gallery-carousel {
    margin-bottom:30px;
    .slide {
        outline:none;
    }
    
    // slick js styles
    .slick-arrow {
        position:absolute;
        top:50%;
        background:none;
        border:none;
        color:$primary-color;
        font-size:48px;
        outline:none;
        z-index:100;
        -webkit-transform:translateY(-50%);
        transform:translateY(-50%);
        &.slick-next {
            right:10px;
        }
        &.slick-prev {
            left:10px;
        }
    }
    
    .slick-dots {
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        padding: 0px;
        text-align: center;
        li {
            list-style: none;
            display: inline-block;
            margin: 0px 5px;
            button {
                background: none;
                border: none;
                outline: none;
                background-color: white;
                width: 18px;
                height: 18px;
                border-radius: 100%;
                color: transparent;
                opacity: 0.7;
            }
            &.slick-active {
                button {
                    background-color: $primary-color;
                    cursor: pointer;
                    opacity: 1;
                }
            }
        }
    }
}

.gallery-lightbox {
    margin-bottom:80px;
    .slide {
        outline:none;
        display:inline-block;
        margin:0px 45px 0px 0px;
    }
    
    // slick js styles
    .slick-arrow {
        position:absolute;
        top:50%;
        background:none;
        border:none;
        color:$primary-color;
        font-size:48px;
        outline:none;
        z-index:100;
        -webkit-transform:translateY(-50%);
        transform:translateY(-50%);
        &.slick-next {
            right:-30px;
        }
        &.slick-prev {
            left:-30px;
        }
    }
    
    .slick-dots {
        position: absolute;
        bottom: -80px;
        left: 0;
        right: 0;
        padding: 0px;
        text-align: center;
        li {
            list-style: none;
            display: inline-block;
            margin: 0px 5px;
            button {
                background: none;
                border: none;
                outline: none;
                background-color: #999;
                width: 18px;
                height: 18px;
                border-radius: 100%;
                color: transparent;
                opacity: 0.7;
            }
            &.slick-active {
                button {
                    background-color: $primary-color;
                    cursor: pointer;
                    opacity: 1;
                }
            }
        }
    }
}



////////////////////////////////////////////////
// Tabs
////////////////////////////////////////////////
.tabs {
    margin-bottom:30px;
    .tab-heading {
        padding:0px;
        display:block;
        border-bottom:2px solid #e0e0e0;
        margin:0px;
        li {
            list-style:none;
            display:inline-block;
            margin-right:10px;
            a {
                position:relative;
                display:block;
                padding:10px 10px;
                color:$secondary-color;
                &:after {
                    content:'';
                    height:2px;
                    z-index:10;
                    position:absolute;
                    left:0px;
                    right:0px;
                    bottom:-2px;
                }
                &.active,
                &:hover {
                    color:$primary-color;
                    &:after {
                        background:$primary-color;
                    }
                }
            }
        }
    }
    
    .tab-content {
        background:#f9f9f9;
        overflow:auto;
        max-height:800px;
        display:none;
        padding:5px 20px;
        &.active {
            display:block;
        }
    }
}

////////////////////////////////////////////////
// End Tabs
////////////////////////////////////////////////


////////////////////////////////////////////////
// Footer Block
////////////////////////////////////////////////

.footer {
    position:relative;
    background:#212326;
    padding:30px 0px;
    margin-top:30px;
    .container {
        padding-left:0px !important;
    }
    
    .nav-wrapper {
        position:relative;
        height:100%;
        
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-align-items:center;        
        -ms-flex-align:center;        
        align-items:center;
        
    }
    .nav {
        > li {
            float:left;
            list-style:none;
            margin-left:0px;
            a {
                display:block;
                font-size:14px;
                margin:0px;
                color:#B1B1B1;
                text-decoration: none;
                background:transparent;
                padding:6px 10px;
                transition:color 0.5s;
                &:hover {
                    color:$primary-color;
                }
            }
            ul {
                padding: 10px 15px;
                position:absolute;
                background:#f0f0f0;
                width:250px;
                top:100%;
                display:none;
                li {
                    list-style:none;
                    a {
                        
                    }
                }
                
            }
            &:hover {
                .dropdown {
                    display:block;
                }
            }
        }
    }
    
    .nav {
        position:relative;
        padding:0px; 
        margin:0px;
    }
    
    .social-links {
        float:right;
        li {
            list-style:none;
            float:left;
            margin-right:10px;
        }
        
    }
    
}


////////////////////////////////////////////////
// End Footer Block
////////////////////////////////////////////////



.digistorm {
    position:relative;
    background:black;
    padding:10px 0px;
    .container {
        padding-left:0px !important;
    }
    
    .nav-wrapper {
        position:relative;
        height:100%;
        
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-align-items:center;        
        -ms-flex-align:center;        
        align-items:center;
        
    }
    
    .logo {
        img {
            height:20px;   
        }
    }
    
    .nav {
        > li {
            float:left;
            list-style:none;
            &:hover {
                color:$primary-color;
            }
            a,
            span {
                display:block;
                font-size:13px;
                margin:0px;
                color:#FFF;
                text-decoration: none;
                background:transparent;
                padding:6px 10px;
                transition:color 0.5s;
                &:hover {
                    color:$primary-color;
                }
            }
            ul {
                padding: 10px 15px;
                position:absolute;
                background:#f0f0f0;
                width:250px;
                top:100%;
                display:none;
                li {
                    list-style:none;
                    a {
                        
                    }
                }
                
            }
            &:hover {
                .dropdown {
                    display:block;
                }
            }
        }
    }
    
    .nav {
        position:relative;
        padding:0px; 
        margin:0px;
    }
    
}