
// screen sizes
$screen-lg:                 75em; // 1200px
$screen-md:                 64em; // 1040px
$screen-sm:                 48em; // 768px
$screen-xs:                 767px; // 45em

// site colors
$primary-color:             #588BC8; // Yellow
$secondary-color:           #212326; // Black

// font colors
$font-color:                #000000;
