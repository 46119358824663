
// tablets
@media(max-width:$screen-md) {
    
   
    
}

// mobiles
@media(max-width: $screen-xs) {
  
}

// extra small mobiles
@media(max-width: 320px) {
    
}



